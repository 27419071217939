import gql from 'graphql-tag';

export const GRAPHQL_FRAGMENTS = {
  rangeFields: gql`
    fragment rangeFields on FloatRange {
      min
      max
    }
  `,
  editorialSegmentFields: gql`
    fragment editorialSegmentFields on EditorialSegment {
      id
      edmundsTypeCategory
      displayName
      segmentRatings(pagesize: 5, filter: { ratingMode: NEW }) {
        rank
        slugRankedSubmodel
        modelYear {
          makeName
          makeSlug
          modelName
          modelSlug
          year
        }
      }
    }
  `,
  suvSegmentFields: gql`
    fragment suvSegmentFields on EditorialSegment {
      id
      edmundsTypeCategory
      displayName
      segmentRatings(pagesize: 1, filter: { ratingMode: NEW }) {
        slugRankedSubmodel
        badges
        modelYear {
          makeName
          makeSlug
          modelName
          modelSlug
          year
          generations {
            generation
            generationYears
          }
        }
      }
    }
  `,
  comparatorSegmentFields: gql`
    fragment comparatorSegmentFields on EditorialSegment {
      edmundsTypeCategory
      displayName
      segmentRatings(pagesize: 4, filter: { ratingMode: NEW }) {
        modelYear {
          makeName
          modelName
        }
        baseStyleId
      }
    }
  `,
  editorialSegmentFieldsFull: gql`
    fragment editorialSegmentFieldsFull on EditorialSegment {
      id
      edmundsTypeCategory
      subsegment
      market
      displayName
      description
      segmentRatings(
        filter: { ratingMode: NEW }
        rankRange: { start: $rankRangeStart, end: $rankRangeEnd }
        pagesize: $pageSize
      ) {
        modelYear {
          makeName
          modelName
          makeSlug
          modelSlug
          year
          publicationStates
          generations {
            generation
            generationYears
          }
        }
        rank
        modelDisplayOverride
        slugRankedSubmodel
        rating
        badges
        note
        priceRange {
          ...rangeFields
        }
        mpgRange {
          ...rangeFields
        }
        edmundsElectricityRange @include(if: $isElectric)
        maxEPAElectricityRange @include(if: $isElectric)
        baseStyleId
      }
    }
  `,
  optionFields: gql`
    fragment optionFields on IOption {
      id
      oemName
      price {
        baseMSRP
        ... on OptionPrice {
          oemDiscountMsrp
        }
      }
      ... on ColorOption {
        colorType
        primaryRGB
      }
      ... on IndividualOption {
        description
      }
    }
  `,
};
